/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'measurements4': {
    width: 16,
    height: 16,
    viewBox: '0 0 22 16',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M20.7 15a10.5 10.5 0 000-8A10.5 10.5 0 001.3 7a10.5 10.5 0 000 8"/><path pid="1" d="M10.6 8.72a2.38 2.38 0 00-2 2.22 2.4 2.4 0 104.8.29 2.38 2.38 0 00-1-2.09l.4-4.89a.22.22 0 00-.41-.09z"/></g></g>',
    lastMod: '1723631710000'
  }
})
