/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'line-dash-dot': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 2',
    data: '<path pid="0" stroke="currentColor" stroke-width="2" d="M0 1h8.5"/><circle pid="1" cx="12" cy="1" r="1.5" fill="currentColor" stroke-width="2"/><path pid="2" stroke="currentColor" stroke-width="2" d="M15.5 1H24"/>',
    lastMod: '1723631710000'
  }
})
