/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'power-off': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M8.333 2.5H6.666v1.567l1.667 1.666zm5 5v3.233l1.5 1.5.167-.167V7.5a1.672 1.672 0 00-1.667-1.667V2.5h-1.667v3.333H8.433L10.1 7.5zm-9.9-4.3L2.258 4.375 5 7.117v4.967L7.916 15v2.5h4.167V15l.4-.4 3.725 3.725 1.175-1.175zm6.983 11.108v1.525h-.833v-1.525l-2.917-2.933V8.783l4.642 4.642z"/>',
    lastMod: '1723631710000'
  }
})
