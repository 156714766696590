/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-left': {
    width: 16,
    height: 16,
    viewBox: '0 0 7 13',
    data: '<g data-name="Layer 2"><path pid="0" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M6 12L.5 6.5 6 1" data-name="Layer 1"/></g>',
    lastMod: '1723631710000'
  }
})
