/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'vehicle': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M15.382 4.758A1.16 1.16 0 0014.278 4H5.722a1.167 1.167 0 00-1.1.758L3 9.25v6a.767.767 0 00.778.75h.778a.767.767 0 00.778-.75v-.75h9.333v.75a.767.767 0 00.778.75h.778a.767.767 0 00.777-.75v-6zM5.994 5.5h8l.809 2.25H5.186zm9.45 7.5H4.556V9.505l.093-.255h10.71l.086.255z" fill="#1a1a1a"/><circle pid="2" cx="1.5" cy="1.5" r="1.5" transform="translate(4.857 9.714)" fill="#1a1a1a"/><circle pid="3" cx="1.5" cy="1.5" r="1.5" transform="translate(12.143 9.714)" fill="#1a1a1a"/>',
    lastMod: '1723631710000'
  }
})
