/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'checked': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0zm0 0h20v20H0z" fill="none"/><path pid="1" d="M13.825 6.317l-5.491 5.492-2.992-2.984L4.167 10l4.167 4.167L15.001 7.5zM10 1.667A8.333 8.333 0 1018.333 10 8.336 8.336 0 0010 1.667zm0 15A6.667 6.667 0 1116.667 10 6.665 6.665 0 0110 16.667z" fill="#1a1a1a"/>',
    lastMod: '1723631710000'
  }
})
