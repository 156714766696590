/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'thermometer': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M12.4 10.8V4.4a2.4 2.4 0 10-4.8 0v6.4a4 4 0 104.8 0M10 3.6a.8.8 0 01.8.8v2.4H9.2V4.4a.8.8 0 01.8-.8z" fill="#1a1a1a"/>',
    lastMod: '1723631710000'
  }
})
