/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'back-arrow': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M17.5 9.167H5.692l2.983-2.992L7.5 5l-5 5 5 5 1.175-1.175-2.983-2.992H17.5z" fill="#fff"/>',
    lastMod: '1723631710000'
  }
})
