/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'zoom': {
    width: 16,
    height: 16,
    viewBox: '0 0 12 12',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><path pid="0" data-name="Rectangle 3917" d="M3 0h2a1 1 0 011 1v4a1 1 0 01-1 1H1a1 1 0 01-1-1V3a3 3 0 013-3z" fill="#c1cfd4"/><path pid="1" data-name="Rectangle 3919" d="M7 6h4a1 1 0 011 1v2a3 3 0 01-3 3H7a1 1 0 01-1-1V7a1 1 0 011-1z" fill="#c1cfd4"/><rect pid="2" x=".5" y=".5" width="11" height="11" rx="2" fill="none" stroke="#c1cfd4"/></g></g>',
    lastMod: '1723631710000'
  }
})
