/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'power-60': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M20 0v20H0V0z" fill="none"/><g fill="#1a1818"><path pid="1" d="M18.619 14.244v-2.382h1.068V8.528h-1.064v-2.42A1.113 1.113 0 0017.515 5H1.117A1.115 1.115 0 000 6.108v8.128a1.115 1.115 0 001.109 1.116h16.4a1.113 1.113 0 001.11-1.108zm-17.147-.29a.116.116 0 01-.109-.116l-.008-7.374a.116.116 0 01.117-.108h15.215a.115.115 0 01.108.108v7.374a.114.114 0 01-.108.108z"/><path pid="2" d="M5.2 7.7h1.5v5H5.2zM2.5 7.7H4v5H2.5zM8 7.7h1.5v5H8z"/></g>',
    lastMod: '1723631710000'
  }
})
