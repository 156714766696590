/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'voltage': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M10 17.98A7.989 7.989 0 012.02 10 7.989 7.989 0 0110 2.02 7.989 7.989 0 0117.98 10c0 .195-.007.392-.021.585a5.422 5.422 0 00-1.578-.516V10A6.388 6.388 0 0010 3.62 6.388 6.388 0 003.62 10a6.388 6.388 0 006.381 6.381h.07a5.447 5.447 0 00.512 1.579c-.201.014-.397.02-.583.02zm-.486-2.387V11.2H7.003l3.6-6.8v4.4h2.4z" fill="#1a1a1a"/><path pid="2" d="M15.501 11.003a4.5 4.5 0 104.49 4.5 4.522 4.522 0 00-4.49-4.5zm2.328 2.562l-1.46 4.063c-.268.735-.413.886-.783.886-.387 0-.543-.172-.789-.886l-1.422-4.063a.941.941 0 01-.075-.328.453.453 0 01.455-.445c.258 0 .414.145.506.467l1.288 4.289h.043l1.385-4.289c.1-.332.252-.467.494-.467a.44.44 0 01.44.445.845.845 0 01-.082.325z" fill="#1a1818"/>',
    lastMod: '1723631710000'
  }
})
