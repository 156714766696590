/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'settings-full': {
    width: 13.533,
    height: 13.912,
    viewBox: '0 0 13.533 13.912',
    data: '<path pid="0" d="M11.939 7.637a5.327 5.327 0 00.044-.681 4.275 4.275 0 00-.051-.681l1.471-1.145a.356.356 0 00.087-.442l-1.391-2.406a.354.354 0 00-.428-.159l-1.732.7a5.119 5.119 0 00-1.174-.681L8.504.302a.351.351 0 00-.348-.3H5.374a.343.343 0 00-.341.3l-.261 1.84a5.228 5.228 0 00-1.174.681l-1.732-.7a.346.346 0 00-.427.159L.055 4.688a.329.329 0 00.087.442l1.471 1.145a4 4 0 00-.015 1.362L.127 8.782a.354.354 0 00-.087.442l1.391 2.406a.352.352 0 00.427.159l1.732-.7a5.09 5.09 0 001.174.681l.261 1.84a.355.355 0 00.348.3h2.782a.338.338 0 00.341-.3l.261-1.84a4.988 4.988 0 001.174-.681l1.732.7a.345.345 0 00.427-.159l1.394-2.409a.337.337 0 00-.087-.442zM6.766 9.564a2.609 2.609 0 112.609-2.609 2.608 2.608 0 01-2.608 2.61z" fill="#fff"/>',
    lastMod: '1723631710000'
  }
})
