/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'certificate': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M10 .833L2.5 4.166v5a10.371 10.371 0 007.5 10 10.371 10.371 0 007.5-10v-5zm5.833 8.333A8.728 8.728 0 0110 17.441a8.728 8.728 0 01-5.833-8.275V5.25L10 2.658l5.833 2.592zm-9.658.492L5 10.833l3.333 3.333L15 7.5l-1.175-1.184-5.492 5.492z" fill="#009bd4"/>',
    lastMod: '1723631710000'
  }
})
