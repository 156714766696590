/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'delete': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M13.334 7.5v8.333H6.667V7.5h6.667m-1.25-5H7.917l-.833.833H4.167V5h11.667V3.333h-2.917zm2.917 3.333h-10v10A1.672 1.672 0 006.667 17.5h6.667a1.672 1.672 0 001.667-1.667z" fill="#009bd4"/>',
    lastMod: '1723631710000'
  }
})
