/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'test_icon_4': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g fill="none" data-name="Layer 2"><path pid="0" data-name="Path 6704" d="M0 0h24v24H0z"/><path pid="1" data-name="Path 6705" d="M19 5v2h-4V5h4M9 5v6H5V5h4m10 8v6h-4v-6h4M9 17v2H5v-2h4M21 3h-8v6h8zM11 3H3v10h8zm10 8h-8v10h8zm-10 4H3v6h8z"/></g>',
    lastMod: '1723631710000'
  }
})
