/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'night': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill="none" d="M0 0h20v20H0z"/><g fill="#1a1a1a"><path pid="1" d="M16.483 14.592A8.344 8.344 0 019.692 1.675 8.332 8.332 0 001.65 10c0 .117.017.233.017.35a4.21 4.21 0 011.65-.35A6.639 6.639 0 016.9 4.083a9.7 9.7 0 00.867 6.75 10.073 10.073 0 005.667 4.883 6.638 6.638 0 01-4.675.842 3.275 3.275 0 01-1.367 1.367 8.325 8.325 0 009.567-3.35 8.498 8.498 0 01-.476.017z"/><path pid="2" d="M5.833 13.334h-.15a2.5 2.5 0 10-2.35 3.333h2.5a1.667 1.667 0 100-3.333z"/></g>',
    lastMod: '1723631710000'
  }
})
